import React, { useEffect, useLayoutEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "../Css/Product.css";
import Breadcrumbs from "../Components/Breadcrumbs";
import ClothCard from "../Components/ClothCard";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import FilterSort from "../Components/FilterSort";
import { get } from "../apiService";
import { Pagination, Slider, Spin } from "antd";
import { MetaTags } from "react-meta-tags";

const Product = () => {
  const { slug } = useParams();
  const [showGroomList, setShowGroomList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  const [allcategory, setAllCategory] = useState([]);
  const [productAllImages, setProductAllImages] = useState([]);
  const [filterColor, setFilterColor] = useState([]);
  const [filterSizes, setFilterSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 25000]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 18,
    total: 0,
  });
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    fetchColor();
    fetchSizes();
  }, []);
  // fet

  useEffect(() => {
    const category = localStorage.getItem("category");
    // Check if 'category' exists and parse it to an array
    if (category) {
      const categoryArray = JSON.parse(category);
      const getCategory = categoryArray.find((v) => v.category_slugs == slug);
      setAllCategory(categoryArray);
      console.log(getCategory);

      if (slug == "kurta-pajama-for-men") {
        // Fetch all products
        fetchProducts();
      } else if (slug === "newArrival") {
        // Fetch new arrivals without passing category_id
        fetchProducts("newArrival");
      } else if (slug !== "") {
        // Fetch products by category
        fetchProducts(getCategory.category_Id);
      }
      else if (getCategory) {
        // Fetch products by category
        fetchProducts(getCategory.category_Id);
      }
    }
  }, [pagination.current, pagination.pageSize, slug]);

  const fetchProducts = async (id) => {
    setLoading(true);
    try {
      let response;

      if (id === undefined) {
        // Fetch all products
        response = await get(
          `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}`
        );
      } else if (id === "newArrival") {
        // Fetch new arrivals, exclude category_id
        response = await get(
          `/product.php?action=fetchNewArival&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&page=${pagination.current}&limit=${pagination.pageSize}`
        );
      } else {
        // Fetch products by category
        response = await get(
          `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}&category_id=${id}`
        );
      }

      if (response && response.list) {
        console.log(response.list);
        setProducts(response.list);

        // If you have pagination information from the API, such as total number of items:
        const totalItems = response.total || 0;

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalItems,
        }));
      }
    } catch (error) {
      console.log("Error fetching product category:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (newPagination) => {
    const { current, pageSize } = newPagination;
    setPagination((prevData) => ({
      ...prevData,
      current,
      pageSize,
    }));
  };

  const fetchColor = async () => {
    try {
      const response = await get(
        `/product.php?action=fetchColors&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`
      );
      setFilterColor(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const fetchSizes = async () => {
    try {
      const response = await get(
        `/product.php?action=fetchSizes&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`
      );
      setFilterSizes(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const fetchPriceRangeProducts = async () => {
    setLoading(true);
    try {
      const response = await get(
        `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&price_from=${priceRange[0]}&price_to=${priceRange[1]}&page=${pagination.current}&limit=${pagination.pageSize}`
      );
      console.log("price Products:", response);
      setProducts(response.list);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSliderChange = (value) => {
    // console.log(value);
    if (value[0] >= value[1]) {
      return;
    }
    setPriceRange(value);
  };

  return (
    <>
      <MetaTags>
        <title>Buy Kurta Pajamas for Men Online in India - Kataria's</title>
        <meta title="Buy Kurta Pajamas for Men Online in India - Kataria's" />
        <meta name="description" content="Explore our exclusive collection of men's kurta pajama online in India. Shop stylish kurta sets in cotton, silk, and designer styles from Katarias today! " />
        <meta name="keywords" content="Kurta pajama for men,kurta pajama with jackets,men kurta,Kurta pajam set,kurta pajama for men green,kurta pajama for men blue,stylish white kurta pajama,black kurta and pajama for men,grey kurta pajama,pink kurta pajama,yellow kurta pajama,kurta pajama for men engagement,kurta pajama for men for diwali,kurta pajama for men for marriage,designer men kurta,Cotton Kurta,Silk kurta for Men,Pathani Kurta Pajama,Long kurta,short Kurta,chikankari kurta men,sherwani kurta,Asymmetric Kurta,mirror work kurta set for men,men kurta pajama with jacket" />
        <link rel="canonical" href="https://katariashubli.com/Readymades-kurta" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Buy Kurta Pajamas for Men Online in India - Kataria's" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://katariashubli.com/" />
        <meta property="og:description" content="Explore our exclusive collection of men's kurta pajama online in India. Shop stylish kurta sets in cotton, silk, and designer styles from Katarias today! " />
        <meta property="og:image" content="https://katariashubli.com/static/media/kartaria%20logo.61ab17e04111050f3cc9.png" />
      </MetaTags>
      <Breadcrumbs />
      <section>
        <div className="container">
          <div className="main-product-page">
            <div className=" product-page-filter-left">
              <h5> FILTERS</h5>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title">Price</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div>
                      <div>
                        <Slider
                          range={{
                            draggableTrack: true,
                          }}
                          min={0}
                          max={25000}
                          defaultValue={priceRange}
                          onChange={handleSliderChange}
                        />
                        <div>
                          Selected Price Range: ₹{priceRange[0]} - ₹
                          {priceRange[1]}
                        </div>

                        <button
                          onClick={fetchPriceRangeProducts}
                          className="btn text-center filter-apply-btn mb-2"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Sub Categories</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-sub-categories-list">
                      <Link
                        to='/collections/kurta-pajama-for-men'
                      >
                        <span className="">All Products</span>
                      </Link>
                      {allcategory?.map((v, i) => (
                        <Link to={`/collections/${v.category_slugs}`}>
                          <span className=""> {v.category_name}</span>
                        </Link>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Color</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-color-list row pr-4 mt-2">
                      {filterColor.map((v, i) => (
                        <div className="col-md-2">
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            <input
                              className="input hidden"
                              name="amshopby[confi_color][]"
                              defaultValue={1152}
                              type="radio"
                            />
                            <a
                              href=""
                              rel="nofollow"
                              data-label="Brown"
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                data-am-js="swatch-item"
                                option-type={1}
                                option-id={1152}
                                option-label="Brown"
                                option-tooltip-thumb=""
                                option-tooltip-value="#702219"
                                data-option-type={1}
                                data-option-id={1152}
                                data-option-label="Brown"
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value="#702219"
                                style={{ backgroundColor: `${v.color_code}` }}
                                bis_skin_checked={1}
                              />
                              <div
                                className="am-shopby-swatch-label hidden"
                                bis_skin_checked={1}
                              >
                                <span className="label">Brown</span>
                                <span className="count">1</span>
                                <span className="filter-count-label">item</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Select Size</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-size-list row mt-2">
                      {filterSizes?.map((v, i) => (
                        <div className="col-md-4">
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            <input
                              className="input hidden"
                              name="amshopby[confi_size][]"
                              defaultValue={5115}
                              type="checkbox"
                            />
                            <a
                              href="https://www..com/in/ethnic/best-man.html?confi_size=5115"
                              rel="nofollow"
                              data-label={36}
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                data-am-js="swatch-item"
                                option-type={0}
                                option-id={5115}
                                option-label={36}
                                option-tooltip-thumb=""
                                option-tooltip-value=""
                                data-option-type={0}
                                data-option-id={5115}
                                data-option-label={36}
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value=""
                                style={{}}
                                bis_skin_checked={1}
                              >
                                {v.product_size}
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
                <hr />
              </Accordion>
            </div>
            <div className="product-page-filter-right ">
              <div
                className={`shadow dropdown-groosmen-outfits mt-2 ${showGroomList ? "" : "hidden"
                  }`}
              >
                <div className="groosmen-outfits-option">
                  <div className="dropdown-inner-list-groosmen-outfits">
                    <div>
                      Weeding <label className="text-muted"> 22</label>
                    </div>
                  </div>
                  <div className="dropdown-inner-list-groosmen-outfits">
                    <div>
                      Weeding <label className="text-muted"> 22</label>
                    </div>
                  </div>
                </div>
              </div>
              <Spin spinning={loading}>
                <div className="row">
                  {Products?.map((v, index) => (
                    console.log("v",v),
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-6 col-clothCard mb-5"
                      key={index}
                    >
                      <Link to={`/product-detail-page/${v.slug}`}>
                        <ClothCard
                          pName={v?.product_name}
                          pDes={v?.product_desc}
                          pImage={v?.colors?.[0]?.image?.[0]?.images} // First image
                          pImagesSecond={
                            v?.colors?.[0]?.image?.[1]?.images // Check if the second image exists
                              ? v?.colors[0]?.image[1].images // Use the second image if it exists
                              : v?.colors?.[0]?.image?.[0]?.images // Otherwise, use the first image
                          }
                          pPrice={v?.colors?.[0]?.product_price || "N/A"}
                          pDiscount={v?.discount}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </Spin>
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(page, pageSize) =>
                  handleTableChange({ current: page, pageSize })
                }
                // showSizeChanger
                // pageSizeOptions={["10", "20", "50", "100"]}
                className="user-list-pagination"
              />
            </div>
          </div>
          <div className="product-cart-list-main-product-main-con">
            <h4>Designer Ethnic Wear for The Best Man</h4>
            <p className="designer-ethnic-wear">
              Looking for the perfect outfit for your Yaar Ki Shaadi? We got
              you covered. Kataria's has the best collection of
              designer ethnic men’s wear that will make you look like the
              most handsome best man at your brother’s wedding. Men’s
              fashion has come a long way in the past years. From plain
              kurtas to fancy silhouettes, men’s fashion trends have become
              more experimental and fun. It is time to ditch the boring
              blacks and blues and jump into a world of lively hues this
              season.
            </p>

            <div className="product-page-details-btn-main-ocn">
              {
                showMore ?
                  null :
                  <>
                    <button onClick={() => setShowMore(true)}>
                      More
                    </button>
                  </>
              }
            </div>

            {
              showMore ?
                <>
                  <div className="product-page-details-main-page-con">
                    <div className="prodiuct-page-details-text-main-con">
                      <h1>Kurta Pajama for Men</h1>
                      <p>A timeless blend of tradition and style, the kurta pajama for men remains a wardrobe essential for every occasion. For a wedding, Diwali, or a casual gathering, Kataria's has a collection to elevate your look.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">Types of Men's Kurta Pajama (कुर्ता पजामा) at Kataria's</h2>
                      <h3>1. Based on Kurta Styles</h3>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Pathani Kurta Pajama</h4>
                        <p>The Pathani kurta pajama has a collared neckline and knee-length design. It is perfect for those wanting a bold, traditional look. Pair it with classic pajamas for unmatched charm.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Sherwani Kurta</h4>
                        <p>Sherwani kurtas offer a regal touch, ideal for weddings and cultural celebrations. Complete the outfit with churidars or dhoti pajamas for a majestic appeal.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Kurta Pajama with Jackets</h4>
                        <p>Elevate your ethnic attire by layering with stylish jackets. A men kurta pajama with jacket creates a contemporary vibe for engagements and festive occasions.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Chikankari Kurta Men</h4>
                        <p>The elegance of chikankari embroidery adds a refined touch to your wardrobe. A white chikankari kurta paired with pajama sets is a timeless choice.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Asymmetric Kurta</h4>
                        <p>Add a modern twist with an asymmetric kurta. These distinct designs pair well with slim-fit pajamas for a trendy yet traditional look.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Side Open Kurta Pajama</h4>
                        <p>This kurta pajama has a unique side-button opening. It works for both formal and casual settings.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Short Kurta Pajama</h4>
                        <p>Short kurtas bring a contemporary flair to traditional wear. Pair them with jeans or pajamas for a relaxed yet stylish look.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Mirror Work Kurta Set for Men</h4>
                        <p>Perfect for sangeet and Mehndi functions, mirror work kurta sets add vibrancy and elegance to your festive attire.</p>
                      </div>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h3>2. Based on Kurta Fabrics</h3>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Cotton Kurta</h4>
                        <p>Breathable and lightweight, cotton kurtas are perfect for summer. Choose options like a stylish white kurta pajama or yellow kurta pajama for an effortlessly fresh look.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Silk Kurta for Men</h4>
                        <p>Silk kurtas like black kurta and pajama for men or grey kurta pajama exude luxury, making them ideal for weddings and grand events.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Blended Viscose</h4>
                        <p>With its lightweight texture and smooth finish, blended viscose kurtas are versatile for both day and evening wear.</p>
                      </div>
                      <div className="prodiuct-page-details-text-main-con-point">
                        <h4>Blended Jacquard</h4>
                        <p>Jacquard kurtas, like the pink kurta pajama, have intricate patterns. They are a top choice for festive celebrations.</p>
                      </div>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">How to Style Kurta Pajama for Different Occasions</h2>
                      <h3>Kurta Pajama for Weddings</h3>
                      <p>Wearing a kurta pajama for men at your wedding symbolizes elegance and cultural pride. This traditional attire not only reflects rich heritage but also adds a personal touch to your special day. A kurta pajama perfectly suits the grandeur of Indian weddings. It makes the groom's presence even more memorable.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h3>Kurta Pajama with Jackets</h3>
                      <p>Pairing the right jacket with your kurta pajama can transform your look. For grand occasions, embroidered Nehru jackets add a regal touch to Pathani kurtas. For contemporary vibes, opt for plain silk jackets over asymmetric or short kurtas. Chikankari kurtas pair well with pastel jackets. Mirror work kurtas shine with minimal dark jackets for balance. Choose a jacket that complements the style and fabric of your kurta for a cohesive, stylish ensemble.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h3>Kurta for Diwali</h3>
                      <p>Diwali, a festival of lights and joy, is the perfect occasion to embrace the elegance of traditional attire. A kurta pajama set not only enhances the festive spirit but also pays homage to cultural heritage. For this celebration, a yellow kurta symbolizes prosperity and cheer. A grey kurta adds a touch of refinement.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h3>Kurta for Parties</h3>
                      <p>A kurta pajama set is the perfect choice for parties, blending comfort with elegance. A mirror work kurta set for men adds sparkle and glamour, while a chikankari kurta offers understated sophistication. These styles ensure you stand out effortlessly, making every gathering memorable.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">How to Choose the Right Kurta & Pajama Color for Men</h2>
                      <p>Select colors based on the event and your skin tone. Pastels like pink kurta pajama suit daytime occasions, while darker hues like black kurta and pajama for men are great for evening wear.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">Which Material is Best for Men's Kurta Pajamas?</h2>
                      <p>For summer, lightweight fabrics like cotton kurta and blended viscose are ideal. In cooler weather, opt for silk kurta for men or blended jacquard for added warmth and elegance.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">What Accessories Go Well with Kurta Pajamas?</h2>
                      <ul>
                        <li><span>Footwear</span> : Traditional jutis, Kolhapuri chappals, or loafers.</li>
                        <li><span>Jewelry</span> : Brooches, malas, or pocket squares.</li>
                        <li><span>Layering</span> : Enhance your outfit with a Nehru jacket or a stylish dupatta.</li>
                      </ul>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">Can Men Combine Kurta with Jeans?</h2>
                      <p>Yes! Pairing a short kurta with jeans creates a semi-casual look, perfect for parties or casual outings.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">What to Wear with Kurta Pajamas?</h2>
                      <p>A kurta pajama set is a versatile choice for parties, offering a perfect mix of style and comfort. To make a striking impression, consider layering with a Nehru jacket for a sharp and polished look. Accessorize with malas or brooches to add a touch of sophistication. A dupatta can elevate your ensemble further, creating a blend of tradition and elegance that ensures you stand out in any gathering.</p>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">How to Take Care of Your Kurta Pajamas</h2>
                      <ul>
                        <li>Always follow care labels.</li>
                        <li>Dry clean delicate fabrics like silk or jacquard.</li>
                        <li>Store in breathable garment bags to maintain their quality.</li>
                      </ul>
                    </div>


                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">Why Shop Kurta Pajamas from Katarias?</h2>
                      <ul>
                        <li><span>Diverse Range</span> : From designer men kurta to long kurta, our collection caters to every preference.</li>
                        <li><span>Premium Fabrics</span> : Experience unmatched comfort and quality.</li>
                        <li><span>Custom Fit</span> : Enjoy free alterations for the perfect fit.</li>
                        <li><span>Secure Shopping</span> : Reliable payment options for a seamless experience.</li>
                      </ul>
                    </div>

                    <div className="prodiuct-page-details-text-main-con">
                      <h2 className="product-page-head-2-underline">Frequently Asked Questions</h2>
                      <div className="prodiuct-page-details-faqs-main-con">
                        <h3>What are the best fabrics for summer kurta pajamas?</h3>
                        <p>Blended cotton and linen are ideal for their lightweight and breathable qualities.</p>
                      </div>
                      <div className="prodiuct-page-details-faqs-main-con">
                        <h3>Can I wear kurta pajamas casually?</h3>
                        <p>Yes, pairing short kurtas with jeans offers a stylish and comfortable look.</p>
                      </div>
                      <div className="prodiuct-page-details-faqs-main-con">
                        <h3>What footwear pairs best with kurta pajamas?</h3>
                        <p>Jutis, Kolhapuri chappals, or loafers are excellent choices.</p>
                      </div>
                    </div>
                  </div>
                  <div className="product-page-details-btn-main-ocn">
                    {
                      showMore ?
                        <>
                          <button onClick={() => setShowMore(false)}>
                            Less
                          </button>
                        </> :
                        null
                    }
                  </div>
                </>
                :
                null
            }
          </div>
        </div>
      </section>
      <FilterSort />
      <Footer />
    </>
  );
};

export default Product;
