import React, { useState } from "react";
import OTP from "./OTP";
import { Button, Form, Input, message } from "antd";
import { IoIosSend } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { post } from "../../apiService";

export default function EmailEnter({ setOtpSubmit, setRememberToken, setUserData }) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpSend, setOtpSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOtpSend = async () => {
    try {
      setLoading(true);
      const value = form.getFieldsValue();

      const formData = new FormData();
      formData.append("user_email", value.email);
      formData.append("action", "register");

      const resp = await post("/user.php", formData);

      if (resp) {
        message.success("OTP sent successfully");
        setOtpSend(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Email id is not registred with our account");
      console.error(error);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setLoading(true);
      const value = form.getFieldsValue();

      const formData = new FormData();
      formData.append("user_email", value.email);
      formData.append("otp", otp.join(""));
      formData.append("action", "verify_signup")

      const resp = await post("/user.php", formData);

      if (resp) {
        setRememberToken(resp?.message?.profile?.user);
        setLoading(false);
        message.success("OTP Verify success");
        setOtpSubmit(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("OTP invalid please try again");
    }
  };

  return (
    <div className="inputCard">
      <h4>Forgot Password</h4>
      <p>Enter your email and we'll send you otp to resetn password</p>
      <Form
        layout="vertical"
        onFinish={otpSend ? handleOtpSubmit : handleOtpSend}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
            { type: "email" },
          ]}
        >
          <Input
            type="email"
            disabled={otpSend}
            prefix={<IoMdMail />}
            className="formInput"
            placeholder="Registred Email ID"
          />
        </Form.Item>
        {otpSend && (
          <OTP otp={otp} setOtp={setOtp} handleOtpSend={handleOtpSend} />
        )}
        <Form.Item>
          {otpSend ? (
            <Button
              htmlType="submit"
              className="addButtons bg-dark text-white"
              icon={<IoSend />}
              loading={loading}
            >
              Submit OTP
            </Button>
          ) : (
            <Button
              htmlType="submit"
              className="addButtons bg-dark text-white"
              icon={<IoIosSend />}
              loading={loading}
            >
              Send OTP
            </Button>
          )}
        </Form.Item>
      </Form>
      <NavLink to="/account/sign-in">
        <FaAngleLeft /> Back to login page
      </NavLink>
    </div>
  );
}
