import React, { useEffect, useState } from "react";
import { get } from "../apiService";
import ClothCard from "./ClothCard";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const HomeProduct = () => {
  const [Products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    // setLoading(true)
    try {
      const page = 1;
      const limit = 6;
      const response = await get(
        `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${limit}&page=${page}`
      );
      if (response && response.list) {
        console.log(response.list);
        setProducts(response.list);
      }
    } catch (error) {
      console.log("Error fetching product category:", error);
    } finally {
      //   setLoading(false)
    }
  };

  return (
    <>
      <section className="mt-2">
        <div className="container">
          <div className="home-section-head-main-con">
            {/* <h2>Unleash Your Unique Style</h2> */}
            <h3 className="prodcut-head-name">Elevate Your Style</h3>
          </div>
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[ Pagination, Navigation]}
            className="mySwiper"
          >
            {Products?.map((v, i) => {
              return (
                <>
                  <SwiperSlide>
                    <Link to={`/product-detail-page/${v.slug}`} key={i}>
                      <ClothCard
                        pName={v.product_name}
                        pImage={v.colors?.[0]?.image?.[0]?.images} // First image
                        pImagesSecond={
                          v.colors?.[0]?.image?.[1]?.images // Check if the second image exists
                            ? v.colors[0].image[1].images // Use the second image if it exists
                            : v.colors?.[0]?.image?.[0]?.images // Otherwise, use the first image
                        }
                        pPrice={v.colors?.[0]?.product_price || "N/A"}
                        pDiscount={v?.discount}
                      />
                    </Link>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HomeProduct;
