import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Header from './Components/Header';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import Home from './Components/Home';
import Product from './Pages/Product';
import ProductDetailsPages from './Pages/ProductDetailsPages';
import Account from './Pages/Account';
import CheckOutPage from './Pages/CheckOutPage';
import Profiles from "./Components/Profile/Profiles";
import SuccessPage from "./Pages/SuccessPage";
import FailedPage from "./Pages/FailedPage";
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import OfferSwiper from './Components/OfferSwiper';
import PaymentPolicy from './Components/Policies/PaymentPolicy';
import PrivacyPolicie from './Components/Policies/PrivacyPolicie';
import ShippingPolicy from './Components/Policies/ShippingPolicy';
import TermsofService from './Components/Policies/TermsofService';
import RefundPolicy from './Components/Policies/RefundPolicy';
import Faq from './Components/Policies/Faq';
import Loader from './Pages/Loader';
import { useEffect, useState } from 'react';
import AudioPlayer from './Pages/AudioPlayer';
import axios from 'axios';
import ForgotPassword from './Components/account/ForgotPassword';

function App() {

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetchIp()
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [])


  const [isActive, setIsActive] = useState("")
  const fetchIp = async () => {
    try {


      const geoResponse = await fetch("https://ipapi.co/json/");
      const geoData = await geoResponse.json();
      console.log(geoData)
      if (geoData) {
        const formData = new FormData();
        formData.append("ip_address", geoData.ip);
        formData.append("country", geoData.country_name);
        formData.append("city", geoData.city);
        formData.append("region", geoData.region);
        formData.append("postal", geoData.postal);
        formData.append("reason", "");
        formData.append("action", "add");

        // Send the data to your backend API
        const resp = await axios.post("https://skdm.in/server/kataria/api/geolocation.php", formData);
        if (resp) {
          setIsActive(resp?.message?.data); // Set the response data to update state
        }
      }
    } catch (error) {
      console.error("Error fetching IP address or geolocation data:", error);
    }
  };
  const { page } = useParams();
  return (
    <div className="App">
      <div className={`content ${loading ? 'loading' : ''}`}>
        <BrowserRouter>
          {/* <Loader/> */}


          {/* <Header /> */}
          <Routes>
            <Route path='/' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <Home /> </>} />
            <Route path='/profiles' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <Profiles /> </>} />
            <Route path='/collections/:slug' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <Product /> </>} />
            <Route path='/contact-page' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <ContactPage /> </>} />
            <Route path='/About-us' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <AboutPage /> </>} />
            <Route path='/product-detail-page/:slug' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <ProductDetailsPages /> </>} />
            <Route path='/checkOutPage' element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <CheckOutPage /> </>} />
            <Route path='/account/:page' element={<Account />} />
            <Route path="/success-page/:id" element={<SuccessPage />} />
            <Route path="/PaymentPolicy" element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <PaymentPolicy /> </>} />
            <Route path="/PrivacyPolicie" element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <PrivacyPolicie /> </>} />
            <Route path="/ShippingPolicy" element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <ShippingPolicy /> </>} />
            <Route path="/TermsofService" element={<> <AudioPlayer /> <OfferSwiper /> <Header /> <TermsofService /> </>} />
            <Route path="/RefundPolicy" element={<>  <AudioPlayer /> <OfferSwiper /> <Header /> <RefundPolicy />  </>} />
            <Route path='/reset-pasword' element={<> <ForgotPassword/></>}/>
            {/* <Route path="/faqs" element={<Faq />} /> */}
            <Route path="/fail-page/:id" element={<FailedPage />} />
          </Routes>
        </BrowserRouter>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default App;
