import React, { useEffect, useState } from 'react';
import "../AddressList/Addresspage.css";
import { FiPlus } from "react-icons/fi";
import { Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import { get, post } from '../../apiService';

const Addresspage = () => {
    const { token } = useSelector(state => state.auth);
    const profile = JSON.parse(localStorage.getItem('profile')) || {}; // Ensure profile is always defined
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        address: "",
        pincode: "",
        alternative_number: profile.phone || "", // Set default to profile.phone
        city: "",
        state: ""
    });

    const [formErrors, setFormErrors] = useState({
        address: "",
        pincode: "",
        alternative_number: "",
        city: "",
        state: ""
    });

    const [userAddress, setUserAddress] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (Object.values(formData).every(value => value.trim() !== "")) {
            const formDataToSend = new FormData();
            formDataToSend.append("action", "add");
            formDataToSend.append("admin_token", 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');
            formDataToSend.append('user_id', profile.user_id);
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });

            try {
                const response = await post("/address.php", formDataToSend);
                if (response && response.status === "success") {
                    setUserAddress(prev => [...prev, formData]); // Directly add formData to the state
                    message.success("Address Added Successfully");
                    setIsModalOpen(false);
                    setFormData({
                        address: "",
                        pincode: "",
                        alternative_number: profile.phone || "", // Reset to profile.phone
                        city: "",
                        state: ""
                    }); // Reset form data
                } else {
                    message.error("Failed to add the address");
                }
            } catch (error) {
                console.error("Error sending data to the API:", error);
                message.error("Error adding address");
            }
        } else {
            updateFormErrors();
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
        setFormErrors(prev => ({
            ...prev,
            [id]: value.trim() ? "" : "This field is required"
        }));
    };

    const fetchData = async () => {
        try {
            const response = await get(`/address.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profile.user_id}&action=fetch&limit=10&page=1`);
            console.log(response);
            setUserAddress(response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onRemoveAddress = async (address_id) => {
        console.log("Attempting to remove address ID:", address_id);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("action", "update");
            formDataToSend.append("admin_token", 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');
            formDataToSend.append("address_id", address_id);
            formDataToSend.append("is_active", '0');

            const response = await post("/address.php", formDataToSend);

            if (response) {
                setUserAddress(prevAddresses => prevAddresses.filter(address => address.address_id !== address_id));
                message.success("Address Removed Successfully");
            } else {
                message.error("Failed to remove the address");
            }
        } catch (error) {
            console.error('Error removing address:', error);
            message.error("Error removing address");
        }
    };

    const updateFormErrors = () => {
        const errors = {};
        Object.keys(formData).forEach(key => {
            errors[key] = formData[key].trim() ? "" : "This field is required";
        });
        setFormErrors(errors);
    };

    return (
        <>
            <section className='ProfileEdit'>
                <div className='ProfileEdit-con'>
                    <div className='ProfileEdit-con-heading'>
                        <h2>Your Addresses</h2>
                    </div>
                    <div className='YourAddresses-main-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='YourAddresses-card-add' onClick={showModal}>
                                    <div className='YourAddresses-card-body-add'>
                                        <div className='YourAddresses-card-body-add-icons'>
                                            <FiPlus />
                                        </div>
                                        <h3>Add Address</h3>
                                    </div>
                                </div>
                            </div>
                            {userAddress.map(elem => (
                                <div className='col-md-6' key={elem.address_id || elem.pincode}>
                                    <div className='YourAddresses-card'>
                                        <div className='YourAddresses-card-body'>
                                            <div className='YourAddresses-card-Para'>
                                                <p>{elem.address},
                                                    {elem.city}, {elem.pincode}, {elem.state}</p>
                                                <h3>Phone Number: <span>{elem.alternative_number}</span></h3>
                                            </div>
                                            <div className='YourAddresses-card-Para-btn'>
                                                <button className='YourAddresses-border-remove' onClick={() => onRemoveAddress(elem.address_id)}>Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Modal title="Add a new address" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <section className='OrderAddress-Popup-Section'>
                    <div className='OrderAddress-Form-MainCon'>
                        <form>
                            {Object.keys(formErrors).map(key => (
                                <div className="form-group" key={key}>
                                    <label htmlFor={key}>{key.replace('_', ' ').toUpperCase()}</label>
                                    <input
                                        type={key.includes("number") ? "number" : "text"}
                                        className={`form-control ${formErrors[key] ? "is-invalid" : ""}`}
                                        id={key}
                                        value={formData[key]}
                                        onChange={handleInputChange}
                                        required
                                        disabled={key === "alternative_number"} // Disable alternative_number field
                                    />
                                    <div className="invalid-feedback">{formErrors[key]}</div>
                                </div>
                            ))}
                        </form>

                    </div>
                </section>
            </Modal>
        </>
    );
};

export default Addresspage;
