import React, { useState } from "react";
import password from "../../Assert/forgot-password-concept-illustration.avif";
import PasswordInput from "../../Components/PasswordReset/PasswordInput";
import EmailEnter from "../../Components/PasswordReset/EmailEnter";
import { Empty } from "antd";
import { FaAngleLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "../../Css/ForgotPassword.css";

export default function ForgotPassword() {
  const [otpSubmit, setOtpSubmit] = useState(false);
  const [remberToken, setRememberToken] = useState(null);

  return (
    <div className="container forgotePassword">
      <div className="row w-100">
        <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
          {otpSubmit ? (
            remberToken && remberToken.length > 0 ? (
              <PasswordInput remberToken={remberToken} />
            ) : (
              <>
                {" "}
                <Empty />
                <h4>Email Id is not registred !</h4>
                <NavLink to="/account/sign-in">
                  <FaAngleLeft /> Back to login page
                </NavLink>
              </>
            )
          ) : (
            <EmailEnter
              setOtpSubmit={setOtpSubmit}
              setRememberToken={setRememberToken}
            />
          )}
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className="imageDiv">
            <img src={password} />
          </div>
        </div>
      </div>
    </div>
  );
}
