import React, { useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { post } from '../../apiService';
import "../modal/AddressModal.css";

const AddressModal = ({ setSelectedAddress, selectedAddress }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const tokenid = localStorage.getItem("access_token");
    const profile = JSON.parse(localStorage.getItem('profile'));
    console.log("profile contact", profile.phone);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async (values) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('alternative_number', values.alternative_number);
            formDataToSend.append('pincode', values.pincode);
            formDataToSend.append('address', values.address_1);
            formDataToSend.append('city', values.city);
            formDataToSend.append('state', values.state);
            formDataToSend.append('action', "add");
            formDataToSend.append('user_id', profile.user_id);
            formDataToSend.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');

            const response = await post('/address.php', formDataToSend);
            console.log('Response from API:', response);

            setIsModalOpen(false);
            message.success('Address added successfully');

            values.action = "1";
            values.active = response.data.address_id;
            setSelectedAddress(values);

            form.resetFields();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add Address
            </Button>
            <Modal visible={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form
                    layout="vertical"
                    onFinish={handleOk}
                    form={form}
                    initialValues={{
                        alternative_number: profile?.phone || '',
                    }}
                >
                    <Form.Item
                        label="Mobile Number"
                        name="alternative_number"
                        rules={[
                            {
                                required: true,
                                message: "Mobile number is required",
                            },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Pincode"
                        name="pincode"
                        rules={[
                            {
                                required: true,
                                message: "Pincode is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Address Line 1"
                        name="address_1"
                        rules={[
                            {
                                required: true,
                                message: "Address Line 1 is required",
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: "City is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: "State is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddressModal;
